import { Header } from "../../components/Layout/Header/Header";
import { Table } from "../../components/Table/Table";

const columns = [
  {label: "Почта", dataKey: 'email' },
  {label: "Номер телефона", dataKey: 'telnum' },
  {label: "Фамилия", dataKey: 'secondName' },
  {label: "Имя", dataKey: 'name' },
  {label: "Отчество", dataKey: 'middleName' },
  {label: "Резидент РБ", dataKey: 'rbResidence' },
  {label: "ИНН", dataKey: 'inn' },
];

const columns2 = [
  { label: "Название актива", dataKey: 'name' },
  { label: "Количество", dataKey: 'quantity' }
];

const data1 = [
  { email: 'alexey@gmail.com', telnum: '+375293334455', name: 'Alex', secondName: 'Smith', middleName: 'Nikolaevich', rbResidence: true, inn: 'ИНН' },
  { email: 'alexey@gmail.com', telnum: '+375293334455', name: 'Alex', secondName: 'Smith', middleName: 'Nikolaevich', rbResidence: true, inn: 'ИНН' }
]
const data2 = [
  { name: 'Tesla', quantity: 10 },
  { name: 'Microsoft', quantity: 10 },
]
export function Profile() {
  return (
    <div>
      <Header />
      <div style={{ width: 900, margin: "100px auto" }}>
        <Table columns={columns} data={data1} />
        <br />
        <Table columns={columns2} data={data2} />
      </div>
    </div>
  );
}
