import { Input } from "../../components/form/Input/Input";
import { Button } from "../../components/form/Button/Button";
import "./login.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Header } from "../../components/Layout/Header/Header";
import { post } from "../../utils/api";

export function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  function handleLogin() {
    const useDefault = 1;
    const creds = useDefault === 1
      ? { email: "alexeyzhytnik@gmail.com", password: "lexa" }
      : { email, password };

    post("https://sprut.one/api/v1/auth/authenticate", creds);
   
    localStorage.setItem("email", email);
    localStorage.setItem("password", password);
  }

  return (
    <div className="login-page">
      <Header />

      <div className="container" style={{ marginTop: 250 }}>
        <Input
          placeholder="Email"
          value={email}
          onChange={(e: any) => {
            setEmail(e.currentTarget.value);
          }}
        />
        <Input
          placeholder="Пароль"
          type="password"
          value={password}
          onChange={(e: any) => {
            setPassword(e.currentTarget.value);
          }}
        />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={handleLogin}>Войти</Button>
        </div>
      </div>

      <div
        className="login-page-container container"
        style={{ marginTop: 100 }}
      >
        <Link to="/registration">Нет аккаунта - зарегистрируйтесь</Link>
        <br />
        <Link to="/restore">Забыли пароль?</Link>
      </div>
    </div>
  );
}
