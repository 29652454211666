import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { MdAccountCircle } from "react-icons/md";
import { MdLogin } from "react-icons/md";
import { IoMdMail, IoMdNotifications } from "react-icons/io";
import "./header.css";

const tempUser = { name: "Alex" };
const loggedIn = false;

export function Header() {
  const email = localStorage.getItem('email');
  const password = localStorage.getItem('password');
  const savedUser = email && password
    ? { email, password }
    : null
  const [user, setUser] = useState<object | null>(savedUser);

  function refreshUser() {

    const email = localStorage.getItem('email');
    const password = localStorage.getItem('password');
    const savedUser = email && password
    ? { email, password }
    : null
    setUser(savedUser)
    console.log('refresh user')
  }

  useEffect(() => {
    window.addEventListener('storage', refreshUser)
    return window.removeEventListener('storage', refreshUser)
  }, []);


  return (
    <nav className="appbar">
      <div>
        <Link to="/">Главная</Link>
        <Link to="/deals">Сделки</Link>
        <Link to="/payments">Пополнение</Link>
        <Link to="/payments">Вывод средств</Link>
        <Link to="/login">Логин</Link>
        <Link to="/registration">Регистрация</Link>
        <Link to="/profile">Профиль</Link>
      </div>
      <div>
        {/* {!user && <Link to="/login"> Войти</Link>} */}
        {/* {!user && <Link to="/login"> <IoMdMail color="white" size={24}/></Link>} */}
        {/* {!user && <Link to="/login"> <IoMdNotifications color="white" size={24}/></Link>} */}
        {!user && <Link to="/login"> <MdLogin color="white" size={24}/></Link>}
        {user && (
          <Link to="/profile">
            <MdAccountCircle color="white" size={24} />
          </Link>
        )}
      </div>
    </nav>
  );
}
