import { IoMdEyeOff } from "react-icons/io";
import { IoMdEye } from "react-icons/io";
import "./input.css";
import { useState } from "react";

type InputProps = any;

export function Input(props: InputProps) {
  const [on, setOn] = useState(true);
  
  const type = props.type === 'password' 
    ? on ? 'password' : 'text' : props.type 
    
  const styles: any = {width: "100%"};

  if (props.error) {
    styles['outlineColor'] = '#d50000';
    styles['borderColor'] = '#d50000';
  }

  return (
    <div style={{ position: "relative", marginTop:8, marginBottom: 8 }} >
        {props.required && <span title="Обязательное для заполнения поле" style={{ position: 'absolute', top: 0, left: -16, color: 'red', cursor: 'help'}}>*</span>}
      <input {...props} style={styles} type={type} />
      <div style={{ position: "absolute", top: 12, right: 12 }}>
        {props.type === "password" && on && (
          <IoMdEye
            size={26}
            color="rgba(0,0,0,.54)"
            onClick={() => setOn(!on)}
            style={{ cursor: "pointer" }}
          />
        )}
        {props.type === "password" && !on && (
          <IoMdEyeOff
            size={26}
            color="rgba(0,0,0,.54)"
            onClick={() => setOn(!on)}
            style={{ cursor: "pointer" }}
          />
        )}
      </div>
      {props.errorMessage && <div style={{color: "#d50000", fontSize: "0.85em", fontWeight: 500}}>
        {props.errorMessage}
      </div>}
    </div>
  );
}
