import './table.css';

type TableColumn = {
  label: string;
  dataKey: string;
}

type TableProps = {
  columns: TableColumn[];
  data: any[];
}

export function Table(props: TableProps) {
  return (
    <table>
      <thead>
        <tr style={{ textAlign: 'left'}}>
          {props.columns.map(c => <th key={c.label}>{c.label}</th>)}
        </tr>
      </thead>
      <tbody>
      {props.data.map(di => 

      <tr key={di}>
        {props.columns.map(c => <td key={c.label}>{di[c.dataKey]}</td>)}
      </tr>
      )}
      </tbody>
    </table>
  )
}