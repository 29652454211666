import { Header } from "../../components/Layout/Header/Header";

export function Deals() {
  return (
    <div>
      <Header />
      Deals
    </div>
  );
}
