import "./App.css";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import { Home } from "./pages/Home/Home";
import { Login } from "./pages/Login/Login";
import { Profile } from "./pages/Profile/Profile";
import { Deals } from "./pages/Deals/Deals";
import { Registration } from "./pages/Registration/Registration";
import { Payments } from "./pages/Payments/Payments";
import { Restore } from "./pages/Restore/Restore";

function App() {
  return (
    <div className="App">
      <BrowserRouter basename="/">
          <Routes>
            <Route path="/" element={<Home />} /> {/* 👈 Renders at /app/ */}
            <Route element={<Login />} path="login" />
            <Route element={<Registration />} path="registration" />
            <Route element={<Profile />} path="profile" />
            <Route element={<Deals />} path="deals" />
            <Route element={<Payments />} path="payments" />
            <Route element={<Restore />} path="restore" />
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
