export function post(url: string, body: object): Promise<unknown> {
  return fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
    // mode: 'no-cors'
  })
  .then((r) => r.json())
  .catch(e => { console.error(e)});
}

export function get<T>(url: string): Promise<T> {
  return fetch(url, { method: "GET" }).then((r) => r.json());
}
