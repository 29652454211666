import { Link } from "react-router-dom";

import { Button } from "../../components/form/Button/Button";
import { Input } from "../../components/form/Input/Input";
import { Header } from "../../components/Layout/Header/Header";

export function Registration() {
  

  return (
    <div className="registration-page">
      <Header />

      <div className="container" style={{ marginTop: 100 }}>
        <form action="">
          <Input placeholder="Фамилия" required error  errorMessage="Wrong"/>
          <Input placeholder="Имя" required />
          <Input placeholder="Отчетсво" />
          <Input placeholder="Email" required />
          <Input placeholder="Телефон" required />
          <Input placeholder="Пароль" type="password" required />
          <Input placeholder="Повторите Пароль" type="password" required />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button>Зарегистрировать</Button>
          </div>
        </form>
      </div>

      <div
        className="registration-page-container container"
        style={{ marginTop: 100 }}
      >
        <input type="checkbox" /> Резидент РБ
        <br />
        <input type="checkbox" /> ИНН
        <br />
        <input type="checkbox" />{" "}
        <Link to="/#">Согласиться с улсовиями сервиса</Link>
        <br />
        <input type="checkbox" />{" "}
        <Link to="/#">
          Cогласиться с условиями обработки персональных данных
        </Link>
      </div>
    </div>
  );
}
